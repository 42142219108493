import React from 'react'
import { motion } from 'framer-motion'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import SEO from "../components/SEO"
import useChildSexualAbusePracticeAreas from '../hooks/use-childabuse-practiceareas'
import { Container, Grid, PagePadding } from '../styles'
import PracticeAreaBlock from '../components/PracticeAreaBlock'

const PracticeBlockPageStyles = {
    marginBottom: "24px"
}

const transition = {
    duration: 0.25,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const container = {
    hidden: { opacity: 0, y: 20 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            staggerChildren: 0.20,
            ...transition
        }
    }
}

const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
}

const PracticeAreasPage = () => {
    const practiceAreas = useChildSexualAbusePracticeAreas();

    return (
        <Layout>
            <PageBanner title="Child Sexual Abuse" subtitle="California Child Sexual Abuse Lawyer" />
            <SEO
              title="Child Sex Abuse Lawyer | Serving all of California"
              metaDesc="A California Law Firm Devoted to Representing Victims of California Sex Abuse."
            />
            <Container>
                <PagePadding>
                    <Grid threeCol
                       variants={container}
                       initial="hidden"
                       animate="show"
                    >
                        {practiceAreas.map(practice => {
                            return (
                                <motion.div variants={item} key={practice.slug}>
                                    <PracticeAreaBlock
                                        practiceUrl={practice.slug}
                                        title={practice.title}
                                        imageSrc={practice.featuredImage.sharp.fluid}
                                        styles={{...PracticeBlockPageStyles}}
                                    />
                                </motion.div>
                            )
                        })}
                    </Grid>
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default PracticeAreasPage
